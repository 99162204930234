import { graphql } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"

import Layout from "src/layout"
import { PageHead, Column, Section, BgImage, Subtitle, Grid, block } from "src/layout/styled"
import { COLORS } from "src/layout/constants"
import MegaphoneIcon from "src/svg/megaphone.svg"

const Block = styled.div`
  ${block}
  h4 {
    color: ${COLORS.pink};
    font-size: 1.2em;
    font-weight: normal;
    margin-top: 0;
  }
`

interface Data {
  table: { childImageSharp: Media }
}

const AgencyPage: GatsbyPage<Data> = ({ data }) => {
  const subtitle = "Une agence d’étude, de conseil et d’évaluation indépendante, scientifique et engagée."

  return (
    <Layout theme="blue" title="Valeurs" description={subtitle}>
      <PageHead>
        <MegaphoneIcon />
        <h1>valeurs</h1>
        <h2>{subtitle}</h2>
      </PageHead>
      <Section>
        <BgImage>
          <Image fluid={data.table.childImageSharp.fluid} />
          <p>
            Nous mettons <strong>la créativité et la puissance des sciences sociales</strong> au service des politiques
            publiques, des projets associatifs et de l’analyse des transformations{" "}
            <strong>sociétales, numériques et environnementales</strong>.
          </p>
        </BgImage>
      </Section>
      <Column>
        <Subtitle>Notre agence</Subtitle>
        <p>
          Nous sommes une{" "}
          <strong>agence d’étude et de conseil, spécialiste des politiques publiques et de l’impact social</strong>.
          Depuis 2014, nous avons pour ambition de transformer les méthodes de l’évaluation, du conseil et de la
          formation.{" "}
        </p>
        <Grid>
          <Block>
            <h4>
              une <strong>ambition politique</strong>
            </h4>
            <p>
              L’Agence Phare a une ambition politique d’accompagner, d’évaluer et de renforcer durablement{" "}
              <strong>l’impact de projets d’utilité publique et d’innovation sociale</strong>. Pour cette raison, nous
              choisissons de travailler avec des clients et des partenaires avec qui nous partageons des valeurs et une
              volonté sincère de contribution à l’<strong>intérêt général</strong> et de <strong>progrès social</strong>
              .
            </p>
          </Block>
          <Block>
            <h4>
              une <strong>ambition scientifique</strong>
            </h4>
            <p>
              L’Agence Phare a une ambition scientifique de construire des passerelles et de nourrir des{" "}
              <strong>collaborations entre l’action publique et la recherche</strong>. Nous valorisons nos travaux dans
              des colloques, séminaires et par l’écriture d’articles académiques. Notre expertise scientifique est
              mobilisée, vulgarisée et partagée dans nos missions.
            </p>
          </Block>
          <Block>
            <h4>
              une <strong>ambition opérationnelle</strong>
            </h4>
            <p>
              Nous mobilisons concrètement les <strong>méthodes de sciences sociales</strong>, aussi bien qualitatives
              que quantitatives, pour réaliser des études, des évaluations de politiques publiques et d’impact social,
              des formations et des accompagnements de qualité. Nous produisons des connaissances rigoureuses et utiles
              qui alimentent des <strong>recommandations opérationnelles</strong> et la création d’outils au service de
              l’autonomisation des acteurs que nous accompagnons.
            </p>
          </Block>
          <Block>
            <h4>
              une <strong>ambition sociale</strong>
            </h4>
            <p>
              Nous pensons que <strong>donner la parole aux personnes inaudibles</strong> est un levier de justice
              sociale et de lutte contre toutes formes d’inégalités. C’est pourquoi nous tenons compte des citoyennes et
              citoyens, dans leur diversité, pour penser les <strong>transformations de l’action publique</strong> et de
              la société civile. Nous faisons dialoguer les décideur·ses, les acteur·rices de terrain et les
              citoyen·nes, pour mieux relever les défis sociaux et environnementaux.
            </p>
          </Block>
        </Grid>
      </Column>
    </Layout>
  )
}

export default AgencyPage

export const query = graphql`
  query {
    table: file(relativePath: { eq: "table.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
